import React from 'react';

import { Table } from 'rsuite';
import { colors } from '../../config/style';

const { Cell } = Table;

const renderColor = (data: string | number, shouldFormatCell: boolean) => {
	if (shouldFormatCell) {
		data = typeof data === 'string' ? parseFloat(data.replace(/,/gi, '')) : data;
		if (data > 0) {
			return colors.success;
		} else if (data < 0) {
			return colors.danger;
		}
	}

	return 'interfit';
};

const renderContent = (data: string | number, type?: string) => {
	const defaultRender = <span className="table-content-edit-span">{data}</span>;
	if (type) {
		switch (type) {
			case 'img':
				return typeof data === 'string' ? <img src={data} alt={data} width={100} /> : defaultRender;
			case 'boolean':
				return (
					<span className="table-content-edit-span">
						{data !== 'undefined' ? data.toString().toUpperCase() : 'Null'}
					</span>
				);

			default:
				return defaultRender;
		}
	}
	return defaultRender;
};

export const FormattedCell = ({ rowData, dataKey, onClick, ...props }: any) => {
	let { caldiff, field } = props;
	const { fieldType = null } = field;
	return (
		<Cell {...props} style={{ color: renderColor(rowData[dataKey], caldiff) }}>
			{renderContent(rowData[dataKey], fieldType)}
		</Cell>
	);
};
