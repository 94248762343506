require('dotenv').config();

let {
	REACT_APP_PRIMARY_COLOR,
	REACT_APP_SECONDARY_COLOR,
	REACT_APP_BACKGROUND_COLOR,
	REACT_APP_TEXT_COLOR,
	REACT_APP_TITLE_FONT_FAMILY,
	REACT_APP_TITLE_FONT_SIZE,
	REACT_APP_SUBTITLE_FONT_FAMILY,
	REACT_APP_SUBTITLE_FONT_SIZE,
	REACT_APP_TEXT_FONT_FAMILY,
	REACT_APP_TEXT_FONT_SIZE,
	REACT_APP_LOGO_NAME,
	REACT_APP_LABEL_COLOR,
	REACT_APP_SUCCESS_COLOR,
	REACT_APP_DANGER_COLOR
} = process.env;

const colors = {
	primary: REACT_APP_PRIMARY_COLOR || '#000000DE',
	secondary: REACT_APP_SECONDARY_COLOR || 'rgb(51, 52, 63)',
	background: REACT_APP_BACKGROUND_COLOR || 'white',
	title: 'rgb(51, 52, 63)',
	text: REACT_APP_TEXT_COLOR || '#333',
	label: REACT_APP_LABEL_COLOR || '#666',
	success: REACT_APP_SUCCESS_COLOR || 'rgb(48,183,0)',
	danger: REACT_APP_DANGER_COLOR || '#ec2639'
};

const logo = REACT_APP_LOGO_NAME || 'favicon.jpg';

const fonts = {
	titleFontFamily: REACT_APP_TITLE_FONT_FAMILY || 'Khula',
	titleFontSize: REACT_APP_TITLE_FONT_SIZE || 24,
	subtitleFontFamily: REACT_APP_SUBTITLE_FONT_FAMILY || 'Khula',
	subtitleFontSize: REACT_APP_SUBTITLE_FONT_SIZE || 18,
	textFontFamily: REACT_APP_TEXT_FONT_FAMILY || 'Khula',
	textFontSize: REACT_APP_TEXT_FONT_SIZE || 14
};

export { colors, fonts, logo };
