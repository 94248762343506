import { Method } from 'axios';
require('dotenv').config();

const { REACT_APP_ADMIN_API_ENDPOINT = 'http://18.167.82.218' } = process.env;

const endpoint = `${REACT_APP_ADMIN_API_ENDPOINT}/api`;
const POST: Method = 'POST';
const PUT: Method = 'PUT';
const GET: Method = 'GET';
const DELETE: Method = 'DELETE';

const listApi = {
	list: {
		url: `${endpoint}/list`,
		method: GET
	},
	create: {
		url: `${endpoint}/list`,
		method: POST
	},
	edit: {
		url: `${endpoint}/list`,
		method: PUT
	},
	delete: {
		url: `${endpoint}/list`,
		method: DELETE
	},
	uploadImage: {
		url: `${endpoint}/upload`,
		method: POST
	}
};

export { listApi };
