import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

// Components
import { MainContainer, Subtitle, CustomTable, ModelForm } from '../../components';
import fields from './fields';

// action
import { useListStore } from '../../stores';

const renderChild = ({ data, deleteNews, updateNews, createNews }: any) => {
	const handleCreate = (params: Object) => {
		createNews(params);
	};

	const handleUpdate = (params: object) => {
		updateNews(params);
	};

	const handleDeletion = (id: string) => {
		deleteNews(id);
	};

	return (
		<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Subtitle subtitle="List" preset="page-subtitle" />
				<ModelForm
					formTitle="Create List Item"
					formName={'newNewletter'}
					submitBtnName={'CREATE'}
					submitBtnType={'submit'}
					submitBtnClass="login-button"
					onSubmitAction={handleCreate}
					fields={[
						{
							id: 0,
							name: 'Title',
							type: 'text',
							rules: { required: true, message: 'Please input title !' }
						},
						{
							id: 0,
							name: 'Detail',
							type: 'textarea',
							rules: { required: true, message: 'Please input detail !' },
							editType: 'textarea'
						},
						{
							id: 0,
							name: 'Link',
							type: 'text',
							rules: { required: true, message: 'Please input link !' }
						}
					]}
				/>
			</div>
			{/* Table */}
			<div style={{ width: '100%', marginTop: 20 }}>
				<CustomTable
					dataList={data || []}
					fields={fields}
					updateAction={handleUpdate}
					deletedAction={handleDeletion}
					redirectLink={'news'}
				/>
			</div>
		</div>
	);
};

const NewsCategoryPage = () => {
	const [ categoryList, setCategoryList ] = useState<any>([]);

	const { list, getNewsList, updateNews, deleteNews, createNews } = useListStore();

	useEffect(
		() => {
			getNewsList();
		},
		[ getNewsList ]
	);

	useEffect(
		() => {
			list && list.length > 0 && setCategoryList(list);
		},
		[ list ]
	);

	let config = {
		data: categoryList,
		updateNews,
		deleteNews,
		createNews
	};

	return <MainContainer child={() => renderChild(config)} />;
};

export default observer(NewsCategoryPage);
