import React from 'react';

// Style
import { colors, fonts } from '../../config/style';

interface TextProps {
	text: string;
	color?: string;
	style?: object;
	weight?: number;
}

export const Text = (props: TextProps) => {
	let { text, color, style, weight } = props;
	return (
		<p
			style={{
				marginBottom: 0,
				color: color || colors.text,
				fontSize: fonts.textFontSize,
				fontFamily: fonts.textFontFamily,
				fontWeight: weight || 400,
				...style
			}}
		>
			{text}
		</p>
	);
};
