import { action, observable } from 'mobx';
import { setLocalStorage, removeAllLocalStorage } from '../utils';
import { history } from './router';
import { message } from 'antd';
export class AuthStore {
	@observable twoFaQrcode: string = '';

	@action.bound
	async login(username: string, password: string) {
		try {
			if (username === 'admin' && password === 'password') {
				setLocalStorage('login', 'yes');

				history.push('/list');
				return true;
			} else {
				message.error('Password is not correct');
				return false;
			}
		} catch (error) {
			console.log('error', error);
			message.error(error && error.message ? error.message : 'Login failed');
			return false;
		}
	}

	@action.bound
	async logout() {
		try {
			removeAllLocalStorage();
			history.push('/login');
			return true;
		} catch (error) {
			removeAllLocalStorage();
			history.push('/login');
		}
	}
}

export const STORE_AUTH = 'authStore';
