import React from 'react';

// Style
import { colors } from '../../config/style';

interface LoginContainerProps {
	child?: any;
}

export const LoginContainer = (props: LoginContainerProps) => {
	const { child } = props;
	return (
		<div
			style={{
				display: 'flex',
				minHeight: 'inherit',
				flex: 1,
				alignItems: 'center',
				justifyContent: 'center',
				background: `linear-gradient(${colors.secondary},${colors.primary})`
			}}
		>
			{/* Child Props */}
			<div
				style={{
					backgroundColor: colors.background,
					boxShadow: '0px 0px 5px #666',
					padding: '50px',
					minWidth: 500,
					// maxHeight: 350,
					textAlign: 'center'
				}}
			>
				{child ? child() : null}
			</div>
		</div>
	);
};
