import React from 'react';

// Components
import { Header, SideMenu } from '../../components';

interface MainContainerProps {
	child?: any;
	styles?: object;
}

export const MainContainer = (props: MainContainerProps) => {
	const { child, styles } = props;
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				flex: 1,
				minHeight: '100vh'
			}}
		>
			{/* Header */}
			<Header />

			<div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
				{/* Side Menu */}
				<SideMenu />

				{/* Child Props */}
				<div style={{ padding: '40px 60px', flex: 1, display: 'flex', ...styles }}>
					{child ? child() : null}
				</div>
			</div>
		</div>
	);
};
