const menus = [
	{
		id: 1,
		name: 'List',
		redirection: '/list',
		allow: true
	}
];

export default menus;
