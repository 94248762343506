import React from 'react';

// Components
import { Input } from 'antd';

// Style

interface SearchProps {
	placeholder?: string;
	onSearch(value: any, event: any): void;
	width?: string | number;
}

export const Search = (props: SearchProps) => {
	let { placeholder, onSearch, width } = props;
	return (
		<div style={{ width: width || 300 }}>
			<Input.Search placeholder={placeholder || 'Search'} onSearch={onSearch} style={{ paddingLeft: 0 }} />
		</div>
	);
};
