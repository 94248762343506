import React, { useEffect, useState } from 'react';

// Components
import { Avatar, Dropdown } from 'antd';
import settingMenu from './settingMenu';
// import { logo } from '../../config/style';
// Assets
// const LOGO = `assets/${logo}`;

interface HeaderProps {}

export const Header = (props: HeaderProps) => {
	const [ lastNameChar, setLastNameChar ] = useState('A');

	useEffect(() => {
		const lastName = localStorage.getItem('lastName');
		lastName && lastName[0] && setLastNameChar(lastName[0].toUpperCase());
	}, []);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				padding: '15px 30px',
				alignItems: 'center',
				boxShadow: '4px 1px 4px #dfdfdf'
			}}
		>
			{/* Logo */}
			<div>
				{/* <a> */}
				{/* <img src={LOGO} width={100} alt={'header-logo'} /> */}
				{/* </a> */}
			</div>

			{/* Avatar */}
			<div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
				<Dropdown overlay={settingMenu} trigger={[ 'click' ]}>
					<Avatar
						style={{ backgroundColor: 'rgb(110,204,219)', verticalAlign: 'middle', cursor: 'pointer' }}
						size="default"
					>
						{lastNameChar}
					</Avatar>
				</Dropdown>
			</div>
		</div>
	);
};
