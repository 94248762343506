import React from 'react';
import { observer } from 'mobx-react';

// Components
import { Line, Subtitle, Form } from '../../components';
import { fields } from './fields';
// import action
import { useAuthStore } from '../../stores';

// Style
import { colors } from '../../config/style';

const LoginForm = () => {
	const { login } = useAuthStore();

	const onFinish = async (values: any) => {
		const { username, password } = values;
		await login(username, password);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<div style={{ minWidth: 300 }}>
			<Subtitle subtitle={'Admin Login'} />
			<Line position={'center'} color={colors.secondary} height={2} />
			{/* Form */}
			<div style={{ maxWidth: 400 }}>
				<Form
					fields={fields}
					formName="login-form"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					submitBtnName="ACCESS TO SYSTEM"
					submitBtnType="submit"
					submitBtnClass="login-button"
				/>
			</div>
		</div>
	);
};

export default observer(LoginForm);
