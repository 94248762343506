import React, { useState } from 'react';
import { Form } from '..';

// Style
import { Modal } from 'antd';
import { iForm } from '../form/types';
import { ButtonHtmlType } from '../button/types';
import { PlusCircleOutlined } from '@ant-design/icons';

interface ModelFormProps {
	fields: Array<iForm>;
	formTitle?: string;
	formName: string;
	submitBtnName: string;
	submitBtnType?: ButtonHtmlType;
	submitBtnClass?: string;
	onSubmitAction?: Function;
	modelStyle?: object;
	modelFooter?: boolean;
}

export const ModelForm = (props: ModelFormProps) => {
	const {
		modelStyle = null,
		modelFooter = false,
		fields,
		formTitle,
		formName,
		submitBtnName,
		submitBtnType = 'button',
		submitBtnClass = 'login-button',
		onSubmitAction
	} = props;

	const [ visible, setVisible ] = useState(false);

	const onFinish = (params: any) => {
		setVisible(false);
		onSubmitAction && onSubmitAction(params);
	};

	const createForm = () => {
		return (
			<Form
				formName={formName}
				submitBtnName={submitBtnName}
				submitBtnType={submitBtnType}
				submitBtnClass={submitBtnClass}
				onFinish={onFinish}
				fields={fields}
			/>
		);
	};

	return (
		<div>
			<div style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setVisible(true)}>
				<PlusCircleOutlined />
			</div>

			<Modal
				style={{ ...modelStyle }}
				onCancel={() => setVisible(false)}
				title={formTitle}
				visible={visible}
				footer={modelFooter}
			>
				{createForm()}
			</Modal>
		</div>
	);
};
