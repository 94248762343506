import React, { useState } from 'react';

import { Table } from 'rsuite';
import { Upload, Progress, Select, Input } from 'antd';
import { Button } from '../../components';
import { listApi } from '../../config/apiConfig';
import axios from 'axios';

const { Cell } = Table;
const { Option } = Select;
const { TextArea } = Input;

interface EditCellProps {
	rowData?: any;
	dataKey: any;
	onChange: any;
	editType?: any;
	editOptions?: any;
	editOptionType?: any;
	secondDataKey?: any;
}

export const EditCell = ({
	secondDataKey,
	rowData,
	dataKey,
	onChange,
	editType,
	editOptions,
	editOptionType,
	...props
}: EditCellProps) => {
	const editing = rowData.editStatus === 'EDIT';
	const [ progress, setProgress ] = useState(0);
	const [ uploadStatus ] = useState(false);
	const uploadImage = async (options: any) => {
		const { onSuccess, onError, file, onProgress } = options;

		const fmData = new FormData();

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			},
			onUploadProgress: (event: any) => {
				const percent = Math.floor(event.loaded / event.total * 100);
				setProgress(percent);
				if (percent === 100) {
					setTimeout(() => setProgress(0), 1000);
				}
				onProgress({ percent: event.loaded / event.total * 100 });
			}
		};

		fmData.append('photo', file);
		try {
			const { url } = listApi.uploadImage;
			const result = await axios.post(url, fmData, config);
			const { data } = result.data;
			onChange && onChange(rowData.id, dataKey, data);
			onSuccess('Ok');
		} catch (err) {
			onError({ err });
		}
	};

	// handle different input type for editing
	const value = secondDataKey ? (rowData[dataKey] ? rowData[dataKey][secondDataKey] : '') : rowData[dataKey];
	let inputForm = (
		<input
			className="rs-input"
			defaultValue={value}
			onChange={(event) => {
				onChange && onChange(rowData.id, dataKey, event.target.value);
			}}
		/>
	);

	if (editType && editType === 'OPTIONS') {
		inputForm = (
			<Select
				defaultValue={value || ''}
				placeholder={value || ''}
				onChange={(value) => {
					onChange && onChange(rowData.id, dataKey, value);
				}}
			>
				{editOptions &&
					editOptions.length > 0 &&
					editOptions.map((option: any) => {
						return (
							<Option key={`input${option}`} value={option}>
								{option}
							</Option>
						);
					})}
			</Select>
		);
	}

	if (editType && editType === 'image') {
		inputForm = (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{progress > 0 ? <Progress percent={progress} /> : null}

				<Upload
					accept="image/*"
					customRequest={uploadImage}
					className="image-upload-grid"
					showUploadList={{
						showRemoveIcon: false
					}}
				>
					<Button loading={uploadStatus} style={{ width: '9em' }} buttonName="UPLOAD" />
				</Upload>
			</div>
		);
	}

	if (editType && editType === 'textarea') {
		inputForm = (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<TextArea
					value={value}
					rows={6}
					onChange={(event) => {
						onChange && onChange(rowData.id, dataKey, event.target.value);
					}}
				/>
			</div>
		);
	}

	return (
		<Cell {...props} className={editing ? 'table-content-editing' : ''}>
			{editing ? (
				inputForm
			) : editType === 'image' ? (
				<a rel="noopener noreferrer" href={value} target="_blank">
					{value}
				</a>
			) : (
				<span className="table-content-edit-span">{value}</span>
			)}
		</Cell>
	);
};
