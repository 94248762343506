import { ApiStore } from './api';
import { action, observable } from 'mobx';
import { listApi } from '../config/apiConfig';
import { message } from 'antd';

export class ListStore {
	@observable list: any[] = [];

	@action.bound
	async getNewsList() {
		try {
			const { url, method } = listApi.list;
			// get call api method
			const { callApi } = new ApiStore();
			const result: any = await callApi(method, url);
			const { data, status } = result;
			console.log('data', data);
			this.list = data;
			return status;
		} catch (error) {
			console.log('error', error);
			return false;
		}
	}

	@action.bound
	async updateNews(params: object) {
		try {
			const { url, method } = listApi.edit;
			// get call api method
			const { callApi } = new ApiStore();

			const result: any = await callApi(method, url, params);
			if (result && result.data && result.status) {
				this.getNewsList();
				message.info('Update news successfully');
			} else {
				message.error('Fail to update news');
			}

			return true;
		} catch (error) {
			message.error(error && error.message ? error.message : 'Fail to update news');
			return false;
		}
	}

	@action.bound
	async deleteNews(id: number) {
		try {
			const { url, method } = listApi.delete;
			// get call api method
			const { callApi } = new ApiStore();

			const result: any = await callApi(method, url, { id });
			if (result && result.data && result.status) {
				this.getNewsList();
				message.info('Delete news successfully');
			} else {
				message.error('Fail to delete news');
			}

			return true;
		} catch (error) {
			message.error(error && error.message ? error.message : 'Fail to delete news');
			return false;
		}
	}

	@action.bound
	async createNews(params: object) {
		try {
			const { url, method } = listApi.create;
			// get call api method
			const { callApi } = new ApiStore();

			const result: any = await callApi(method, url, params);
			if (result && result.data && result.status) {
				this.getNewsList();
				message.info('Create news successfully');
			} else {
				message.error('Fail to create news');
			}

			return true;
		} catch (error) {
			message.error(error && error.message ? error.message : 'Fail to create news');
			return false;
		}
	}
}

export const STORE_LIST = 'listStore';
