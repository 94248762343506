const fields = [
	{
		name: 'ID',
		editable: false,
		dataKey: 'id'
	},
	{
		name: 'Title',
		editable: true,
		dataKey: 'title'
	},
	{
		name: 'Details',
		editable: true,
		dataKey: 'detail',
		editType: 'textarea'
	},
	{
		name: 'Order',
		editable: true,
		dataKey: 'order'
	},
	{
		name: 'Button Image',
		editable: true,
		dataKey: 'btnImageUrl',
		editType: 'image'
	},
	{
		name: 'Button Image',
		editable: true,
		dataKey: 'insideImageUrl',
		editType: 'image'
	}
];

export default fields;
