import React from 'react';

import { Table, Button } from 'rsuite';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';

const { Cell } = Table;

export const ActionCell = ({ rowData, dataKey, onClick, ...props }: any) => {
	return (
		<Cell {...props} style={{ padding: '6px 0' }}>
			<Button
				appearance="link"
				onClick={() => {
					onClick && onClick(rowData.id);
				}}
			>
				{rowData.editStatus === 'EDIT' ? <SaveOutlined /> : <EditOutlined />}
			</Button>
		</Cell>
	);
};
